
import { ComputedRef, defineComponent, PropType, computed } from "vue";
import { Router, useRouter } from "vue-router";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import {
  AdminStatus,
  IUserAccount
} from "@/store/interfaces/accounts/IUserAccount";
import { adminsFilterStatuses } from "@/enums/admins/adminsFilters";
import {
  infoWithImage,
  ITableViewConfiguration
} from "@/components/Table/ts/interfaces/common";
import { userId } from "@/store/interfaces/auth/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { dateConverter } from "@/utills/dateConverter";

type tableHeadCells = ITableHeadCell[];

export type adminsTableSort = {
  byName: sortItem;
  byDateCreated: sortItem;
};

export default defineComponent({
  name: "AdminsTable",
  components: { AppTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    // base
    const rowDetailsBaseUrl = "/admin";
    const rowDetailsQueryKey = "id";

    const router: Router = useRouter();

    const initialViewConfiguration = { page: 1, perPage: 10 };

    const initialSortOptions: adminsTableSort = {
      byName: {
        order: sortOrders.asc,
        keyName: "fullName",
        keyNameInModel: "fullName"
      },
      byDateCreated: {
        order: sortOrders.turnOff,
        keyName: "dateCreated",
        keyNameInModel: "createdAt"
      }
    };

    // row actions
    const userEditHandler = (id: userId) => rowClickHandler(id);
    const userDeleteHandler = (id: userId) => emit("delete", id);

    async function rowClickHandler(id: userId): Promise<void> {
      await router.push({
        path: rowDetailsBaseUrl,
        query: {
          [rowDetailsQueryKey]: id
        }
      });
    }

    // table model
    const accountsList: ComputedRef<IUserAccount[]> = computed(
      () => props.accountsList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration,
      initialSortOptions,
      model: accountsList,
      generateRows(accountsList: IUserAccount[]): ITableBodyRow[] {
        return accountsList.map(
          (account: IUserAccount): ITableBodyRow => {
            const {
              id,
              emailVerified,
              fullName,
              email,
              image,
              phone,
              createdAt
            } = account;

            const isVerified: boolean = emailVerified;
            let status: AdminStatus = adminsFilterStatuses.active;

            if (!isVerified) {
              status = adminsFilterStatuses.inActive;
            }

            return {
              status,
              onClick: (): void => {
                userEditHandler(id);
              },

              actions: [
                {
                  text: "Edit",
                  onClick: (): void => {
                    userEditHandler(id);
                  }
                },
                {
                  text: "Remove",
                  onClick: (): void => {
                    userDeleteHandler(id);
                  },
                  cls: "danger"
                }
              ],

              cells: [
                {
                  infoWithImage: {
                    title: fullName,
                    image
                  } as infoWithImage
                },
                { label: email },
                { label: phone || "" },
                { label: dateConverter(new Date(createdAt || "")) },
                { label: status }
              ]
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    accountsList: {
      type: Array as PropType<IUserAccount[]>,
      required: true
    },

    sort: {
      type: Object as PropType<adminsTableSort>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byName } = this.currentSort;
      return [
        {
          size: "xl",
          sort: {
            keyName: byName.keyName,
            order: byName.order
          },

          label: "name",
          id: "user-name"
        },
        {
          size: "lg",
          label: "email",
          id: "user-email"
        },
        {
          size: "md",
          label: "mobile",
          id: "user-mobile"
        },
        {
          size: "md",
          label: "Date Created",
          sort: {
            keyName: byName.keyName,
            order: byName.order
          },
          id: "user-created"
        },
        {
          size: "sm",
          label: "status",
          id: "user-status"
        },
        {
          size: "xxs",
          label: "",
          id: "user-actions"
        }
      ];
    }
  }
});
